import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ModelOutline from '../assets/images/Group 60.svg';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

export default function AfterSaleSection() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        p: 6,
      }}
    >
      <Grid
        container
        spacing={2}
        rowGap={4}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid
          item
          xs={12}
          mt={2}
          md={6}
          sx={{ textAlign: 'justify' }}
        >
          <Box sx={{ maxWidth: '30rem', mx: 'auto' }}>
            <Typography
              variant='h2'
              mb={5}
            >
              After-Sale Service
            </Typography>
            <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 5 }}>
              <img
                src={ModelOutline}
                alt='model Outline'
                width={'100%'}
              />
            </Box>
            <Typography
              variant='body2'
              mb={5}
            >
              We strive to offer the highest level of support to ensure that
              your experience with our products is seamless and trouble-free. We
              understand that having reliable support is crucial, which is why
              we offer comprehensive warranty and after-sale services for your
              peace of mind.
            </Typography>
            <Grid
              container
              justifyContent={'space-between'}
            >
              <Stack>
                <Box>
                  <Typography variant='body2'>Warranty</Typography>
                  <Typography variant='body2'>Coverage</Typography>
                </Box>
                <GppGoodOutlinedIcon
                  sx={{
                    fontSize: '4rem',
                    opacity: (theme) => theme.typography.body2.opacity,
                  }}
                />
              </Stack>

              <Stack>
                <Box>
                  <Typography variant='body2'>Technical</Typography>
                  <Typography variant='body2'>Support</Typography>
                </Box>
                <EngineeringOutlinedIcon
                  sx={{
                    fontSize: '4rem',
                    opacity: (theme) => theme.typography.body2.opacity,
                  }}
                />
              </Stack>

              <Stack>
                <Box>
                  <Typography variant='body2'>Professional</Typography>
                  <Typography variant='body2'>Team</Typography>
                </Box>
                <GroupsOutlinedIcon
                  sx={{
                    fontSize: '4rem',
                    opacity: (theme) => theme.typography.body2.opacity,
                  }}
                />
              </Stack>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          sx={{ display: { xs: 'none', md: 'block' } }}
          md={6}
        >
          <img
            src={ModelOutline}
            alt='model Outline'
            width={'100%'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
