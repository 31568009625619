import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Logo from '../assets/images/logo.png';
import LogoWhite from '../assets/images/logo white.png';

const navItems = ['Models', 'About', 'Book a demo', 'Contact'];

const handleClick = (navItem: string) => () => {
  console.log(navItem.toLocaleLowerCase().replace(/\s+/g, '-'));
  document
    .getElementById(navItem.toLocaleLowerCase().replace(/\s+/g, '-'))
    ?.scrollIntoView({ behavior: 'smooth' });
};

export default function NavBar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const onClick = (item: string) => () => {
    handleClick(item)();
    setMobileOpen(false);
  };
  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item}
            disablePadding
          >
            <ListItemButton
              sx={{ textAlign: 'center' }}
              onClick={onClick(item)}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        component='nav'
        elevation={0}
        color={'black'}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, my: 2 }}>
            <img
              src={Logo}
              alt='Inventa'
              height={62}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button
                key={item}
                sx={{ color: '#fff' }}
                onClick={handleClick(item)}
              >
                {item}
              </Button>
            ))}
          </Box>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'flex', sm: 'none' },
            flexDirection: 'column',
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
              bgcolor: 'primary.main',
              color: 'white',
            },
          }}
        >
          <IconButton
            color='inherit'
            aria-label='close drawer'
            edge='end'
            onClick={handleDrawerToggle}
            sx={{ ml: 'auto', mr: 2, mt: 1 }}
          >
            <CloseIcon />
          </IconButton>

          {drawer}
          <Box sx={{ ml: 'auto', mr: 2, mt: 'auto', mb: 2 }}>
            <img
              src={LogoWhite}
              alt='Inventa'
              height={115}
            />
          </Box>
        </Drawer>
      </nav>
    </Box>
  );
}
