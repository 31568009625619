import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ModelImage from '../assets/images/X01 Printed.webp';
import SVGICON from '../assets/images/Path 31.webp';
import Form from './form';

const headline = (small: boolean) => (
  <Typography
    variant='h2'
    mb={5}
    sx={{
      display: { xs: !small ? 'none' : 'block', md: small ? 'none' : 'block' },
    }}
  >
    Model
    <Typography
      component={'span'}
      variant='h2'
      sx={{ display: 'block' }}
      color={'primary.main'}
    >
      X01
    </Typography>
  </Typography>
);
export default function ModelSection() {
  const [open, setOpen] = useState(false);
  return (
    <Box
      id='models'
      sx={{
        minHeight: '100vh',
        bgcolor: 'black.main',
        color: 'black.contrastText',
        p: 4,
      }}
    >
      <Grid
        container
        spacing={2}
        rowGap={4}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          {headline(true)}
          <img
            src={ModelImage}
            alt='X01 model'
            width={'100%'}
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={2}
          md={6}
          sx={{ textAlign: 'justify' }}
        >
          <Box sx={{ maxWidth: '30rem', mx: 'auto' }}>
            {headline(false)}

            <Typography
              variant='body2'
              mb={5}
            >
              The Model X01 is designed for those who demand the best in 3D
              printing technology. Engineered for high-speed performance and
              unparalleled precision, this state-of-the-art machine brings your
              ideas to life with extraordinary speed and accuracy.
            </Typography>
            <Button
              variant='outlined'
              sx={{ color: 'white' }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Enquire
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        columns={10}
        mt={2}
        justifyContent={'center'}
      >
        <Grid
          item
          xs={5}
          md={2}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
          >
            <img
              src={SVGICON}
              alt='pattern'
              width={'13.38px'}
            />
            <Stack justifyContent={'space-between'}>
              <Typography
                variant='body2'
                fontSize='0.6rem'
              >
                Working Principle
              </Typography>
              <Typography
                variant='body1'
                fontSize='1.2rem'
              >
                FDM
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
          >
            <img
              src={SVGICON}
              alt='pattern'
              width={'13.38px'}
            />
            <Stack justifyContent={'space-between'}>
              <Typography
                variant='body2'
                fontSize='0.6rem'
              >
                Printing Speed
              </Typography>
              <Typography
                variant='body1'
                fontSize='1.2rem'
              >
                300 mm/s
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
          >
            <img
              src={SVGICON}
              alt='pattern'
              width={'13.38px'}
            />
            <Stack justifyContent={'space-between'}>
              <Typography
                variant='body2'
                fontSize='0.6rem'
              >
                Rated Power
              </Typography>
              <Typography
                variant='body1'
                fontSize='1.2rem'
              >
                350W
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
          >
            <img
              src={SVGICON}
              alt='pattern'
              width={'13.38px'}
            />
            <Stack justifyContent={'space-between'}>
              <Typography
                variant='body2'
                fontSize='0.6rem'
              >
                Bed Temperature
              </Typography>
              <Typography
                variant='body1'
                fontSize='1.2rem'
              >
                Up to 100 °C
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
          >
            <img
              src={SVGICON}
              alt='pattern'
              width={'13.38px'}
            />
            <Stack justifyContent={'space-between'}>
              <Typography
                variant='body2'
                fontSize='0.6rem'
              >
                Printing Volume
              </Typography>
              <Typography
                variant='body1'
                fontSize='1.2rem'
              >
                295x300x300 mm
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Form
        open={open}
        setOpen={setOpen}
        demo={false}
      />
    </Box>
  );
}
