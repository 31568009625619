import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ModelImage from '../assets/images/About.webp';

export default function AboutSection() {
  return (
    <Box
      id='about'
      sx={{
        minHeight: '100vh',
        bgcolor: 'black.main',
        color: 'black.contrastText',
        p: 4,
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        direction={{ xs: 'column-reverse', md: 'row' }}
        mb={2}
      >
        <Grid
          item
          xs={12}
          mt={{ xs: 0, md: 2 }}
          md={6}
          sx={{ textAlign: 'justify' }}
        >
          <Box sx={{ maxWidth: '30rem', mx: 'auto' }}>
            <Typography
              variant='h2'
              mb={5}
            >
              Inventa
              <Typography
                component={'span'}
                variant='h2'
                sx={{ display: 'block' }}
                color={'primary.main'}
              >
                Industry
              </Typography>
            </Typography>

            <Typography
              variant='body2'
              mb={5}
            >
              Inventa is an Egyptian startup manufacturer of lightweight
              machinery, born out of years of perseverance and innovation. We
              specialize in designing and producing high-speed, efficient
              machines that meet international standards while offering the
              advantage of comprehensive customer support.
            </Typography>
            <Typography
              variant='body2'
              mb={5}
            >
              Our commitment to excellence ensures that our customers receive
              exceptional service and reliability, making Inventa the go-to
              choice for high-quality, locally produced machinery. With a
              dedicated team always ready to assist, we stand by our promise of
              delivering superior performance and customer satisfaction.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
        >
          <img
            src={ModelImage}
            alt='X01 model light'
            width={'100%'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
