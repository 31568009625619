import { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import videoSrc from '../assets/images/video.mp4';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export default function VideoSection() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  return (
    <Box sx={{ position: 'relative', bgcolor: 'black.main' }}>
      <Box
        onClick={handleTogglePlay}
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          bgcolor: isPlaying ? 'transparent' : 'primary.main',
          opacity: '0.5',
          transition: 'all 0.5s',
          zIndex: '2',
          ':hover': {
            opacity: '0.3',
            cursor: 'pointer',
          },
        }}
      />
      <video
        src={videoSrc}
        loop
        preload='auto'
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        muted={true}
        playsInline
        ref={videoRef}
      />
      <PlayCircleIcon
        sx={{
          position: 'absolute',
          left: '50%',
          top: '57%',
          transform: 'translate(-50%,-50%)',
          fontSize: '5rem',
          color: 'white',
          opacity: isPlaying ? 0 : 1,
          transition: 'all 0.5s',
        }}
      />
    </Box>
  );
}
