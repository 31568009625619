import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import BackgroundPattern from '../assets/images/Group 71.svg';

export default function ContactSection() {
  return (
    <Box
      id='contact'
      sx={{
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        p: 6,
      }}
    >
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography
          variant='h2'
          mb={5}
        >
          Contact Us
        </Typography>

        <Box sx={{ maxWidth: '30rem' }}>
          <Typography
            variant='body2'
            mb={5}
          >
            We’re here to assist you with any questions or concerns you might
            have. Whether you’re interested in learning more about our
            high-speed 3D printers, need technical support, or want to schedule
            a demo session, please contact us at: info@inventa-industries.com
          </Typography>
        </Box>
        <img
          src={BackgroundPattern}
          alt='pattern'
          width={'100%'}
        />
        <Box sx={{ maxWidth: '30rem' }}>
          <Typography
            variant='body2'
            mt={2.5}
            mb={2.5}
          >
            Stay connected with us for the latest updates and news
          </Typography>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <IconButton
              sx={{ color: 'white', opacity: 0.7 }}
              aria-label='facebook'
              href='https://www.facebook.com/inventa.industries'
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              sx={{ color: 'white', opacity: 0.7 }}
              aria-label='instagram'
              href='https://www.instagram.com/inventa_industries'
            >
              <InstagramIcon />
            </IconButton>
          </Stack>
        </Box>
        <Typography
          fontSize={'0.75rem'}
          variant={'body2'}
          mt={2.5}
          mb={2.5}
        >
          &copy; 2024 INVENTA INDUSTRIES
        </Typography>
      </Stack>
    </Box>
  );
}
