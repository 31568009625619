import { createTheme } from '@mui/material/styles';
import KmrApparet from './assets/Fonts/KMR-Apparat-Regular.otf';

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    black: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#061196',
    },
    black: {
      main: '#000',
      light: '#222',
      dark: '#000',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: { textTransform: 'uppercase' },
    h2: { textTransform: 'uppercase' },
    body2: {
      opacity: 0.7,
    },
    fontFamily: 'KMR-Apparat, San',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'KMR-Apparat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('KMR-Apparat'), local('KMR-Apparat-Regular'), url(${KmrApparet}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});
