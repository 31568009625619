import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import ModelImage from '../assets/images/Hero.webp';

export default function HeroSection() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: 'black.main',
        color: 'black.contrastText',
        p: 4,
      }}
    >
      <Toolbar />
      <Grid
        container
        spacing={2}
        rowGap={4}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid
          item
          xs={12}
          mt={2}
          md={8}
          sx={{ textAlign: 'justify' }}
        >
          <Box sx={{ maxWidth: '30rem', mx: 'auto' }}>
            <Typography
              variant='h1'
              mb={10}
              fontSize={{ xs: '2.5rem', sm: '3.5rem', md: '4.5rem' }}
            >
              Revolutionizing 3d printing
            </Typography>

            <Typography variant='body2'>
              Introducing the first fully designed and manufactured Egyptian
              lightweight machines, offering international-level quality &
              features.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          <img
            src={ModelImage}
            alt='3d printer'
            width={'100%'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
