import { useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ModelImage from '../assets/images/X01.webp';
import BackgroundPattern from '../assets/images/Group 37.svg';
import Form from './form';

const headline = (small: boolean) => (
  <Typography
    variant='h2'
    mb={5}
    sx={{
      display: { xs: !small ? 'none' : 'block', md: small ? 'none' : 'block' },
    }}
  >
    Book an X01 Demo
  </Typography>
);
export default function DemoSection() {
  const [open, setOpen] = useState(false);

  return (
    <Box
      id='book-a-demo'
      sx={{
        minHeight: '100vh',
        bgcolor: 'black.main',
        color: 'black.contrastText',
        p: 4,
      }}
    >
      <Grid
        container
        spacing={2}
        rowGap={4}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        mb={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          {headline(true)}
          <img
            src={ModelImage}
            alt='X01 model'
            width={'100%'}
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={2}
          md={6}
          sx={{ textAlign: 'justify' }}
        >
          <Box sx={{ maxWidth: '30rem', mx: 'auto' }}>
            {headline(false)}

            <Typography
              variant='body2'
              mb={5}
            >
              Discover the Power of Model X01!
            </Typography>
            <Typography
              variant='body2'
              mb={5}
            >
              Ready to see our high-speed Model X01 3D printer in action?
              Schedule a demo session and experience firsthand how Model X01 can
              transform your projects.
            </Typography>
            <Typography
              variant='body2'
              mb={5}
            >
              Click on the button below to complete the form by providing your
              details, and someone from our team will contact you to confirm the
              details and finalize the test-drive date.
            </Typography>
            <Button
              variant='outlined'
              sx={{ color: 'white' }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Book
            </Button>
          </Box>
        </Grid>
      </Grid>
      <img
        src={BackgroundPattern}
        alt='Pattern'
        width={'100%'}
      />
      <Form
        open={open}
        setOpen={setOpen}
        demo={true}
      />
    </Box>
  );
}
