import NavBar from './components/nav';
import HeroSection from './components/hero';
import VideoSection from './components/video';
import ModelSection from './components/model';
import AfterSaleSection from './components/afterSale';
import DemoSection from './components/demo';
import AboutSection from './components/about';
import ContactSection from './components/contact';
import { CssBaseline } from '@mui/material';

function App() {
  return (
    <>
      <CssBaseline />
      <NavBar />
      <HeroSection />
      <VideoSection />
      <ModelSection />
      <AfterSaleSection />
      <DemoSection />
      <AboutSection />
      <ContactSection />
    </>
  );
}

export default App;
